export default function Skills() {
  return (
    <div id="skillsDiv">
      <h2>HTML</h2>
      <h2>CSS</h2>
      <h2>JavaScript</h2>
      <h2>React</h2>
      <h2>VSCode</h2>
    </div>
  );
}
